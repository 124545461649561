// const sqlanywhere = require('sqlanywhere');
export class DBAdapter {
    _conn;
    _DBConfig;
    _rpcClientAdapter;
    _rpcServerAdapter;
    _isServer = false;
    // _timeout = 10000; // 10 seconds
    constructor(DBConfig, rpcClientAdapter, rpcServerAdapter) {
        this._DBConfig = DBConfig;
        // mqRPC adapter
        this._rpcClientAdapter = rpcClientAdapter;
        // mqServer adapter
        this._rpcServerAdapter = rpcServerAdapter;
        // track external
        window.$integration = {
            server: false,
            client: false 
        };
        window.$system = {
            connected: false
        }
    }
    query(queryString, attributes = []) {
        return new Promise((resolve ) => {
            this._rpcClientAdapter({ 
                    queryString,
                    attributes
                },
                function rpcQueryResults(results) {
                    window.$integration.client = true;
                    // resolve response
                    resolve(results);
                }
            );
        });
    }
}